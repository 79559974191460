@use 'direction';

.mat-form-field {
  width: 100%;
  line-height: 1.2;
}

.mat-placeholder-required {
  display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-slide-toggle-bar {
  background-color: var(--theme-input-border-color);
}

.mat-checkbox-frame,
.mat-radio-outer-circle {
  border-color: var(--theme-input-border-color);
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: var(--theme-input-border-color);
  opacity: 0.5;
}

.mat-datepicker-toggle,
.mat-datepicker-content {
  color: var(--theme-input-border-color);
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #000000;
}

// ######################################################
// NEW DESIGN BY AHMED ISLAM
// ######################################################

span.mat-form-field-label-wrapper {
  overflow: visible;
}

.mat-form-field.native-select-input {
  max-width: 250px;
  padding-top: 28px;

  .mat-form-field-infix::after {
    margin-top: -10.5px;
  }

  span.mat-form-field-label-wrapper {
    top: -50px;
    height: 30px;
    padding: 0;

    label {
      padding-top: 8px;
    }
  }

  mat-icon.icon {
    z-index: 1;
  }

  select.mat-input-element:not(:hover):not(:focus) {
    border-color: var(--theme-border-color);
  }
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
  label.mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  label.mat-form-field-label {
  display: block;
  width: 100%;
  pointer-events: all;
  transform: translateY(-25px);

  mat-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 23px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;

    .hint-icon {
      cursor: pointer;
    }
  }
}

.mat-form-field-appearance-legacy div.mat-form-field-wrapper {
  padding: 0;

  .mat-form-field-infix {
    padding: 0;
    border: none;
  }

  .mat-form-field-subscript-wrapper {
    position: relative;
    margin-top: 8px;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.mat-form-field {
  padding: 0;

  .mat-form-field-wrapper {
    margin-top: 1.2em;

    input,
    textarea,
    mat-select {
      margin-top: 0.8em;
    }
  }

  &.hide-label .mat-form-field-wrapper {
    margin-top: 0;

    input,
    textarea,
    mat-select {
      margin-top: 0;

      &.mat-input-element {
        margin-top: 0;
      }
    }
  }

  &.ng-touched.ng-invalid {
    .mat-input-element:not(:focus) {
      border-color: var(--warn-color);
    }
  }

  .mat-input-element {
    box-sizing: border-box;
    height: 40px;
    padding: 12px 10px;
    letter-spacing: normal;
    color: var(--theme-input-text-color);
    background: var(--theme-input-background-color);
    border: 1px solid var(--theme-border-color);
    border-radius: var(--theme-border-radius);

    &:is(select) {
      padding-block: 0;
    }

    &:focus {
      border-color: var(--primary-color);
    }

    &:hover {
      border-color: var(--primary-color);
    }

    &[disabled] {
      color: #a1a2af;
      border-color: #e0e0e4;
    }
  }
}

.mat-checkbox {
  &.mat-checkbox-checked {
    .mat-checkbox-background {
      .mat-checkbox-frame {
        border-radius: var(--theme-border-radius);
      }
    }
  }
}

.mat-form-field.select-input {
  .mat-form-field-infix {
    width: auto;
    padding: 0;
  }

  .mat-select:not([aria-disabled='true']):focus .mat-select-trigger {
    border-color: var(--primary-color);
  }

  .mat-select-value {
    max-width: unset;
  }

  .mat-select-trigger {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 12px 0;
    letter-spacing: normal;
    border: 1px solid var(--theme-border-color);
    border-radius: var(--theme-border-radius);
  }

  .mat-select:not([aria-disabled='true']) .mat-select-trigger:hover {
    border-color: var(--primary-color);
  }
}

mat-form-field.mat-form-field-datepicker {
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    position: static;
  }

  mat-datepicker-toggle {
    position: absolute;
    top: 18px;
  }
}

// Override mat-option-text (it inherits the subheader 2 font size)
mat-option {
  .mat-option-text {
    font-size: 16px;
  }
}

// Date picker toggle needs to be large enough to easily interact with
mat-datepicker-toggle {
  font-size: 24px;
}

// DIRECTION
@include direction.biDirectionalViewEncapsulationNone() using($start, $end) {
  .mat-form-field {
    .mat-input-element {
      &.has-icon {
        padding-#{$start}: 40px;
      }

      &.searchable {
        padding-#{$end}: 40px;
      }
    }
  }

  .mat-form-field.select-input {
    .has-icon {
      .mat-select-value {
        padding-#{$start}: 40px;
      }
    }

    .mat-select-value {
      padding-#{$start}: 10px;
    }

    &.native-select-input {
      .mat-input-element {
        padding-#{$end}: 28px;
      }

      .mat-form-field-infix::after {
        #{$end}: 10px;
      }
    }
  }

  .mat-select-arrow-wrapper {
    padding-#{$start}: 8px;
    padding-#{$end}: 12px;
  }

  mat-form-field.mat-form-field-datepicker {
    mat-datepicker-toggle {
      #{$end}: 5px;
    }

    .mat-input-element {
      padding-#{$end}: 30px;
    }
  }
}
